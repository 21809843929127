import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import tw, { styled } from 'twin.macro'
import { LocaleContext } from '../components/layout'
import background from '../images/bluehexagon_faded.svg'
import KeyBenefits from '../components/solutions/key-benefits'
import WatchElementAnimate from '../components/watch-element-animate'
import Help from '../components/services/help'
import Testimonial from '../components/home/testimonial'
import Approach from '../components/services/approach'
import AIWork from '../components/services/ai-work'
import SEO from '../components/SEO/SEO'
import { withPreview } from 'gatsby-source-prismic'
import LocalizedLink from '../components/localized-link'
import {
  LargeSolidTurquoiseButton,
  LargeOutlineTealButton,
} from '../utils/styles'

// styles
const HeroWrapper = styled.div`
  background:#030406;
  padding: 138px 0;
  background-position: 200% 68%;
  @media (max-width: 640px) {
    background-size: 120% 100%;
    background-position: 100% 20%;
    padding-bottom: 30px;
  }
`
const ServiceItemWrapper = styled.div`
  padding: 80px 0;
  ul {
    list-style: circle;
    li {
      padding: 0.25rem
    }
  }
`
// markup
const ServicesPage = ({
  data: { prismicServices },
  pageContext: { locale },
}) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  return (
    <main>
      <SEO
        title={prismicServices.data.meta_title}
        desc={prismicServices.data.meta_description}
        locale={locale}
      />
      <HeroWrapper>
        <div tw="container">
          <div>
            <div tw="whitespace-normal">
              <span tw="text-teal text-sm tracking-widest font-bold uppercase">
                {prismicServices.data.top_headline}
              </span>
              <div
                dangerouslySetInnerHTML={{
                  __html: prismicServices.data.hero_headline.html,
                }}
                tw="text-white"></div>
              <p tw="text-xl text-white py-8">
                {prismicServices.data.hero_subheadline.text}
              </p>
            </div>
          </div>
        </div>
      </HeroWrapper>
      <ServiceItemWrapper>
        <div tw="container">
        <div tw="flex flex-col">
          <div tw="flex flex-row py-12 items-center justify-between w-full">
              <div tw="max-w-2xl">
                <h2 tw="pb-4">Shopify</h2>
                <ul>
                <li>Shopify theme development</li>
                <li>Shopify store setup including products setup, transactional email, payment, shipping system, upsell/downsell funnel, SEO etc..</li>
                <li>Shopify APP development</li>
                <li>Maintain and conversion rate optimization</li>
                </ul>
              </div>
              <div>
              <LocalizedLink to="/request-meeting" tw="hidden md:block">
                <LargeSolidTurquoiseButton tw="max-w-sm px-12">
                  Request a quote
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
              </div>
          </div>
          <div tw="flex flex-row py-12 items-center justify-between w-full">
              <div tw="max-w-2xl">
                <h2 tw="pb-4">WooCommerce </h2>
                <ul>
                <li>WooCommerce theme development</li>
                <li>WooCommerce store setup including products setup, transactional email, payment, shipping system, upsell/downsell funnel, SEO etc..</li>
                <li>WooCommerce plugin development</li>
                <li>Maintain and conversion rate optimization</li>
                </ul>
              </div>
              <div>
              <LocalizedLink to="/request-meeting" tw="hidden md:block">
                <LargeSolidTurquoiseButton tw="max-w-sm px-12">
                  Request a quote
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
              </div>
          </div>
          <div tw="flex flex-row py-12 items-center justify-between w-full">
              <div tw="max-w-2xl">
                <h2 tw="pb-4">Headless E-commerce</h2>
                <ul>
                <li>Build Gatsby frontend based on your design</li>
                <li>Woocommerce, Shopify or any other backend API, product setup</li>
                <li>Payment, transactional email, shipping system setup</li>
                </ul>
              </div>
              <div>
              <LocalizedLink to="/request-meeting" tw="hidden md:block">
                <LargeSolidTurquoiseButton tw="max-w-sm px-12">
                  Request a quote
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
              </div>
          </div>
          <div tw="flex flex-row py-12 items-center justify-between w-full">
              <div tw="max-w-2xl">
                <h2 tw="pb-4">Custom E-commerce</h2>
                <ul>
                <li>We can bulld a custom e-commerce for you if your business process is unique</li>
                <li>We can build a RoR APP or Gatsby/React APP with AWS amplify.</li>
                </ul>
              </div>
              <div>
              <LocalizedLink to="/request-meeting" tw="hidden md:block">
                <LargeSolidTurquoiseButton tw="max-w-sm px-12">
                  Request a quote
                </LargeSolidTurquoiseButton>
              </LocalizedLink>
              </div>
          </div>
          
          </div>
        </div>
      </ServiceItemWrapper>
      {prismicServices.data.body.map((b, index) => {
        switch (b.slice_type) {
          case 'key_benefits':
            return <KeyBenefits benefits={b.primary} items={b.items} />
          case 'ways_we_can_help_you':
            return (
              <Help
                help={b.primary}
                items={b.items}
                title={i18n.services.title}
              />
            )
          case 'client_testimonial':
            return (
              <WatchElementAnimate
                className={`.services__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`services__animated__${index}`}>
                  <Testimonial testimonial={b.primary} />
                </div>
              </WatchElementAnimate>
            )
          case 'our_approach':
            return (
              <WatchElementAnimate
                className={`.services__animated__${index}`}
                animateName={'animate__fadeInUp'}>
                <div className={`services__animated__${index}`}>
                  <Approach approach={b.primary} items={b.items} />
                </div>
              </WatchElementAnimate>
            )
          case 'lets_make_ai_work':
            return (
              <AIWork ai={b.primary} items={b.items} flow={'row'} i18n={i18n} />
            )
          default:
            return null
        }
      })}
    </main>
  )
}

export default withPreview(ServicesPage)

export const pageQuery = graphql`
  query ServicesQuery($locale: String!) {
    prismicServices(lang: { eq: $locale }) {
      data {
        meta_title
        meta_description
        top_headline
        hero_button
        body {
          ... on PrismicServicesBodyKeyBenefits {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
            }
            items {
              card_description
              card_headline
              learn_more {
                url
                slug
              }
              card_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicServicesBodyWaysWeCanHelpYou {
            slice_type
            primary {
              ways_description
              headline {
                text
              }
            }
            items {
              way_description
              way_headline
              way_icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicServicesBodyClientTestimonial {
            primary {
              name
              role
              text
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              link {
                link_type
                type
                lang
                uid
              }
              button
            }
            slice_type
          }
          ... on PrismicServicesBodyOurApproach {
            slice_type
            primary {
              headline {
                text
              }
              subheadline {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              mobile_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            items {
              approach_description
              approach_headline
            }
          }
          ... on PrismicServicesBodyLetsMakeAiWork {
            slice_type
            primary {
              subheadline {
                text
              }
              headline {
                text
              }
            }
            items {
              description {
                html
              }
              icon {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        hero_headline {
          html
        }
        hero_subheadline {
          text
        }
        hero_image {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
  }
`
